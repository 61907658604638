import React, { useState, useEffect, useContext } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";

import { APISV } from "../configs/API";
import PhoneInput from "react-phone-input-2";
import { AppContext } from "../configs/AppContext";
import { useNavigate } from "react-router-dom";

const ModalFormUpdatePhone = ({ show, handleClose, customer }) => {
  const [state, dispatch] = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [dialCode, setDialCode] = useState("");
  const [phone, setPhone] = useState("");
  const navigate = useNavigate();

  const removeDialCode = (value, dialCode) => {
    return value.replace(`${dialCode}`, "");
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true)

      if (!phone) {
        alert('phone number required')
        throw new Error('phone number required')
      }

      const payload = {
        email: customer.email,
        dial_code: dialCode,
        phone: removeDialCode(phone, dialCode),
      }

      const res = await APISV.patch(`account/update/${customer.id}`, payload);

      if (res.data.error != 0) return alert(res.data.message);

      dispatch({
        type: "SET_TOAST",
        payload: {
          isOpen: true,
          message: "Berhasil Menambahkan Nomor Telepohone",
        },
      });

      dispatch({
        type: "SET_ACTIVE_CUSTOMER",
        payload: res.data.data,
      });

      navigate("/products");


    } catch (error) {
      alert(error.response.data.message);
    } finally {
      setLoading(false)
    }
  };

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Body>
        <div className="d-flex justify-content-between mb-3">
          <div className="fw-bold">Form Update Phone</div>
          <button onClick={handleClose} style={{ all: 'unset' }}>
            <IoMdClose size={20} className="cursor-pointer" />
          </button>
        </div>

        <form onSubmit={handleSubmit}>


          <div className="d-flex justify-content-between my-3">
            <div>Pengguna belum memasukan nomor telphonnya </div>
          </div>

          <PhoneInput
            country={"id"}
            placeholder=""
            value={phone}
            onChange={(e, data) => {
              setPhone(e);
              setDialCode(data.dialCode);
            }}
            countryCodeEditable={false}
            inputClass="form-control w-100 h-48"
          />


          <div className="w-100">
            <button
              type="submit"
              className="btn btn-primary w-100 mt-3"
              disabled={loading}
            >
              {loading ? (
                <Spinner
                  animation="border"
                  style={{ color: "#ffff", width: "15px", height: "15px" }}
                />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalFormUpdatePhone;
