import NoImg from "../assets/images/no-img.jpg";

export const defaultSmall = (url) => {
  if (!url) {
    return NoImg;
  }

  let webURL = url;

  if (url.substr(url.length - 5) !== ".webp") {
    if (url.substr(url.length - 5) === ".jpeg") {
      webURL = url.substr(0, url.length - 4) + "webp";
    } else {
      webURL = url.substr(0, url.length - 3) + "webp";
    }
  }

  const str = webURL;

  const filename = str.replace(/^.*[\\\/]/, "");

  const sub = str.replace(filename, "");

  return sub + "small/" + filename;
};

export const defaultMedium = (url) => {
  if (!url) {
    return NoImg;
  }
  let webURL = url;

  if (url && url.substr(url.length - 5) !== ".webp") {
    if (url.substr(url.length - 5) === ".jpeg") {
      webURL = url.substr(0, url.length - 4) + "webp";
    } else {
      webURL = url.substr(0, url.length - 3) + "webp";
    }
  }

  const str = webURL;

  const filename = str.replace(/^.*[\\\/]/, "");

  const sub = str.replace(filename, "");

  return sub + "medium/" + filename;
};

export const noImage = () => {
  return NoImg;
};
