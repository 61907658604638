import React, { useContext, useEffect } from "react";

import Accurate from "../assets/images/accurate.png";

import { APIO2O } from "../configs/API";
import { AppContext } from "../configs/AppContext";
import { APP_VERSION } from "../configs/App";

const Login = () => {
  const [state, dispatch] = useContext(AppContext);

  const getRedirect = async () => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      let newTab = window.open("", "_self", "");

      const res = await APIO2O.get("token/aol/redirect");

      // newTab.location.href = res.data.data.redirect.replace('https://o2omaster.doss.co.id', process.env.REACT_APP_REDIRECT );
      newTab.location.href = res.data.data.redirect;

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      console.log(error.response);

      alert(error.response.data.message);

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    }
  };

  useEffect(() => {
    dispatch({
      type: "SET_LOADING",
      payload: false,
    });
  }, []);

  return (
    <div className="login-container">
      <div className="">
        <div className="card-default login-card mb-2">
          <div>
            <h2 className="text-center fw-bold mb-5">Login dengan Accurate</h2>
            <div className="w-100 d-flex align-items-center justify-content-center">
              <img
                src={Accurate}
                alt="logo accurate"
                style={{ width: "220px", height: "auto" }}
              />
            </div>

            <button
              className="btn btn-primary w-100 fw-bold my-4"
              style={{ minHeight: "50px" }}
              onClick={() => getRedirect()}
            >
              Klik disini untuk login dengan accurate
            </button>
            <p style={{ textAlign: 'center', fontSize: 12, }} className="text-muted">v{APP_VERSION} &copy;DOSS - @{new Date().getFullYear()}</p>

          </div>
        </div>

      </div>
    </div>
  );
};

export default Login;