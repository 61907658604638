import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AppContext } from "../configs/AppContext";

// Components
import ModalLoading from "../components/ModalLoading";

const PrivateRoute = () => {
  const [state, dispatch] = useContext(AppContext);

  return state.isLogin == undefined ? (
    <ModalLoading show />
  ) : state.isLogin == true ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
