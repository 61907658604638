import React, { useEffect, useState } from "react";
import { Modal, Container, Card, ListGroup } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { AiOutlineRight } from "react-icons/ai";
import { APIDOSS } from "../configs/API";

//Configs

// import { stringToSlug } from "../../../configs/helper";

const ModalGenereEvent = ({
  show,
  handleClose,
  choose,
  reset,
  activeId,
}) => {

  const [genres, setGenres] = useState([]);

  const getCategories = async () => {
    try {
      const res = await APIDOSS.get("events/genre?page=1");
      setGenres(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const handleChoose = (e) => {
    // window.location.href = (`/event/${stringToSlug(e.slug)}`);
    choose(e);
    handleClose();
  };

  const handleReset = () => {
    reset();
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Container className="pb-4">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "5%",
          }}
        >
          <h5 className="change-address-title">Genre Event</h5>
          <div className="modal-event-close-btn">
            {activeId !== null && (
              <div className="reset mr-2" onClick={() => handleReset()}>
                Reset
              </div>
            )}

            <MdClose
              className="close"
              size={25}
              onClick={() => handleClose()}
            />
          </div>
        </div>

        <Card>
          <ListGroup variant="flush">
            {genres.length > 0 &&
              genres.map((category, index) => {
                return (
                  <ListGroup.Item
                    key={index}
                    onClick={() =>
                      activeId == category.id
                        ? handleReset()
                        : handleChoose(category)
                    }
                    className="event-category-list-card"
                  >
                    <div className="event-category-list-container">
                      <div className="event-category-list">
                        <div
                          style={{
                            color: activeId == category.id ? "#E66424" : null,
                            fontWeight:
                              activeId == category.id ? "600" : null,
                          }}
                        >
                          {category.title} <span>({category.event_count})</span>
                        </div>
                      </div>

                      <AiOutlineRight />
                    </div>
                  </ListGroup.Item>
                );
              })}
          </ListGroup>
        </Card>
      </Container>
    </Modal>
  );
};

export default ModalGenereEvent;
