import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import NumberFormat from "react-number-format";
import Skeleton from "react-loading-skeleton";
import { IoMdRefresh } from "react-icons/io";
import { AppContext } from "../configs/AppContext";

import Sidebar from "../components/Sidebar";
import TopNavbar from "../components/TopNavbar";
import ModalQRInvoice from "../components/ModalQRInvoice";
import { APISV } from "../configs/API";

const CustomerHistory = () => {
  const [state, dispatch] = useContext(AppContext);
  const [transactionList, setTransactionList] = useState(null);
  const [modalQR, setModalQR] = useState({
    isOpen: false,
    url: "",
  });
  const { activeCustomer } = state;
  const location = useLocation();
  console.log("location", location.state);

  const getTransactions = async () => {
    try {
      const res = await APISV.get(
        `s-cart/transaction?customer_id=${activeCustomer.id}&limit=3`
      );
      setTransactionList(res.data.data);
    } catch (error) {
      if (error.response.data) {
        alert(error.response.data.message);
      } else {
        alert(error);
      }
    }
  };

  const handleRefresh = () => {
    setTransactionList(null);
    getTransactions();
  };

  const handleOpenQR = () => {
    setModalQR({ isOpen: true, url: location.state.inv_url });
  };

  const handleOpenPay = (url) => {
    setModalQR({ isOpen: true, url });
  };

  useEffect(() => {
    getTransactions();
  }, []);

  useEffect(() => {
    if (location.state && location.state.inv_url) {
      setModalQR({ isOpen: true, url: location.state.inv_url });
    }
  }, [location.state]);

  return (
    activeCustomer && (
      <>
        <ModalQRInvoice
          show={modalQR.isOpen}
          handleClose={() => setModalQR({ isOpen: false, url: "" })}
          url={modalQR.url}
        />

        <div className="main-container">
          <Sidebar />

          <div
            className="w-100"
            style={{
              paddingLeft: state.collapse ? "80px" : "270px",
              transition: "width, left, right, 0.3s",
            }}
          >
            <TopNavbar title="History" disableSearch={true} />

            <div className="d-flex justify-content-between px-4">
              <div>
                <h1 className="header-h1">Riwayat Transaksi</h1>
                <h5 className="header-h5">
                  Nama Customer: {activeCustomer.fullname}
                </h5>
              </div>
            </div>

            <div className="p-4">
              <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                {location.state && location.state.inv_url && (
                  <div className="btn btn-secondary" onClick={handleOpenQR}>
                    Lihat Link Pembayaran
                  </div>
                )}
                <div
                  className="btn btn-secondary align-items-center d-flex gap-1"
                  onClick={handleRefresh}
                >
                  <IoMdRefresh size={20} /> Refresh
                </div>
              </div>
              <div className="table-search-card">
                <table>
                  <tr>
                    <th>Order ID</th>
                    <th>Total</th>
                    <th>Status</th>
                    <th>Order Date</th>
                    <th>Last Updated</th>
                    <th className="text-center">Action</th>
                  </tr>

                  {!transactionList && (
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  )}
                  {transactionList &&
                    transactionList.map((transaction) => (
                      <tr key={transaction.id}>
                        <td>{transaction.order_id}</td>
                        <td>
                          <NumberFormat
                            value={transaction.total}
                            displayType={"text"}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix={"IDR "}
                          />
                        </td>
                        <td>
                          <div
                            className={`label-status ${
                              transaction.status_text === "Complete"
                                ? "green"
                                : transaction.status_text === "Payment Pending"
                                ? "red"
                                : "orange"
                            }`}
                          >
                            {transaction.status_text}
                          </div>
                        </td>
                        <td>{moment(transaction.created_at).format("lll")}</td>
                        <td>{moment(transaction.updated_at).format("lll")}</td>
                        <td className="text-center">
                          {transaction.inv_url && (
                            <div
                              className="pay-btn"
                              onClick={() => handleOpenPay(transaction.inv_url)}
                            >
                              Bayar
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default CustomerHistory;
