import React, { useContext } from "react";
import NumberFormat from "react-number-format";

import { useNavigate } from "react-router-dom";

import { AppContext } from "../configs/AppContext";
import { defaultMedium } from "../helpers/defaultImage";

import { APISV } from "../configs/API";

import { IoTrash } from "react-icons/io5";
import { IoIosAddCircle } from "react-icons/io";

const ProductCard = ({ product }) => {
  const [state, dispatch] = useContext(AppContext);
  let activeCart = localStorage.getItem("active_cart");
  let activeCustomer = localStorage.getItem("active_customer");

  const branch = JSON.parse(localStorage.getItem("branch"));
  const warehouse = JSON.parse(localStorage.getItem("warehouse"));
  const dbList = JSON.parse(localStorage.getItem("db_list"));
  const { id: branch_id } = branch;
  const { id: warehouse_id } = warehouse;

  if (activeCart) {
    activeCart = JSON.parse(localStorage.getItem("active_cart"));
  }

  if (activeCustomer) {
    activeCustomer = JSON.parse(localStorage.getItem("active_customer"));
  }

  const navigate = useNavigate();

  const isInCart = (itemId) => {
    if (activeCart) {
      const isCart =
        activeCart.items &&
        activeCart.items.find((item) => item.product_id === itemId);

      if (isCart) {
        return true;
      } else {
        return false;
      }
    } else {
      return null;
    }
  };

  const addToCart = async (product_id) => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const payload = {
        product_id,
        quantity: 1,
        branch_id,
        warehouse_id,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      if (activeCart) {
        if (activeCustomer) {
          const resCreate = await APISV.post(
            `s-cart/${activeCart.id}/item`,
            payload,
            config
          );

          const body = { user_id: activeCustomer.id };
          await APISV.patch(`s-cart/${activeCart.id}`, body, config);

          if (resCreate.data.error === 0) {
            // if (!resCreate.data.available_in_accurate) {
            //   dispatch({
            //     type: "SET_LOADING",
            //     payload: false,
            //   });
            //   dispatch({
            //     type: "SET_TOAST",
            //     payload: {
            //       isOpen: true,
            //       message: resCreate.data.message,
            //     },
            //   });
            //   return
            // }
            const resCart = await APISV.get(`cart/${activeCart.order_id}`);

            if (resCart.data.error === 0) {
              dispatch({
                type: "SET_ACTIVE_CART",
                payload: resCart.data.data,
              });
            } else {
              dispatch({
                type: "DELETE_ACTIVE_CART",
              });
              dispatch({
                type: "DELETE_ACTIVE_TAX",
              });
            }

            dispatch({
              type: "SET_TOAST",
              payload: {
                isOpen: true,
                message: "Berhasil ditambahkan ke keranjang",
              },
            });
          } else if (resCreate.data.error === 404) {
            dispatch({
              type: "DELETE_ACTIVE_CART",
            });
          } else {
            alert(
              `Error ${resCreate.data.error} | ${resCreate.data.message} \nDB: ${dbList.alias} [${dbList.id}] \nBranch: ${branch.name} [${branch.id}] \nWarehouse: ${warehouse.name} [${warehouse.id}]`
            );
          }
        } else {
          navigate("/customers/search");
        }
      } else {
        if (activeCustomer) {
          const res = await APISV.post(`s-cart`);

          const body = { user_id: activeCustomer.id };
          await APISV.patch(`s-cart/${res.data.data.id}`, body, config);

          const resItem = await APISV.post(
            `s-cart/${res.data.data.id}/item`,
            payload,
            config
          );

          // if (!resItem.data.available_in_accurate) {
          //   dispatch({
          //     type: "SET_LOADING",
          //     payload: false,
          //   });
          //   dispatch({
          //     type: "SET_TOAST",
          //     payload: {
          //       isOpen: true,
          //       message: resItem.data.message,
          //     },
          //   });
          //   return
          // }

          if (resItem.data.error === 0) {
            dispatch({
              type: "SET_TOAST",
              payload: {
                isOpen: true,
                message: "Berhasil ditambahkan ke keranjang",
              },
            });
          } else {
            alert(
              `Error ${resItem.data.error} | ${resItem.data.message} \nDB: ${dbList.alias} [${dbList.id}] \nBranch: ${branch.name} [${branch.id}] \nWarehouse: ${warehouse.name} [${warehouse.id}]`
            );
          }

          const resCart = await APISV.get(`cart/${res.data.data.order_id}`);
          if (resCart.data.error === 0) {
            dispatch({
              type: "SET_ACTIVE_CART",
              payload: resCart.data.data,
            });
          } else {
            dispatch({
              type: "DELETE_ACTIVE_CART",
            });
            dispatch({
              type: "DELETE_ACTIVE_TAX",
            });
          }
        } else {
          navigate("/customers/search");
        }
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      if (error.response.data) {
        alert(error.response.data.message);
      } else {
        alert(error);
      }
    }
  };

  const removeFromCart = async (product_id) => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const isCart =
        activeCart.items &&
        activeCart.items.find((item) => item.product_id === product_id);

      const resDel = await APISV.delete(
        `s-cart/${activeCart.id}/item/${isCart.id}`,
        null,
        config
      );

      if (resDel.data.error === 0) {
        const resCart = await APISV.get(`cart/${activeCart.order_id}`);
        if (resCart.data.error === 0) {
          dispatch({
            type: "SET_ACTIVE_CART",
            payload: resCart.data.data,
          });
        } else {
          dispatch({
            type: "DELETE_ACTIVE_CART",
          });
          dispatch({
            type: "DELETE_ACTIVE_TAX",
          });
        }
      } else {
        alert(`Error ${resDel.data.error} | ${resDel.data.message}`);
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });

      dispatch({
        type: "SET_TOAST",
        payload: {
          isOpen: true,
          message: "Berhasil dihapus dari keranjang",
        },
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      alert(error.response.data.message);
    }
  };

  return (
    <div key={product.id || product.productId} className="product-card">
      <img
        src={
          product.image_link
            ? defaultMedium(product.image_link)
            : defaultMedium(product.featured_image)
        }
        alt={product.name}
        title={product.name}
        onClick={() =>
          window.open(`https://doss.co.id/product/${product.slug}`, "_blank")
        }
      />

      <div className="product-content">
        <div className="d-flex justify-content-between align-items-center mt-2">
          <div className="product-id-txt">#{product.id || product.item_id}</div>
          {product.stock && (
            <div className="product-stock">Stock: {product.stock}</div>
          )}

          {product.product_label === "Out of Stock" && (
            <div className="product-stock-gray">{product.product_label}</div>
          )}
        </div>
        <div
          title={product.name}
          className="product-name-txt mt-3"
          onClick={() =>
            window.open(`https://doss.co.id/product/${product.slug}`, "_blank")
          }
        >
          {product.name}
        </div>

        <NumberFormat
          value={
            product.discount_price ? product.discount_price : product.price
          }
          displayType={"text"}
          thousandSeparator="."
          decimalSeparator=","
          prefix={"IDR "}
          className="product-price-txt mt-2"
        />
      </div>

      {isInCart(product.id) === true && (
        <IoTrash
          size={35}
          className="product-add-icon"
          onClick={() => removeFromCart(product.id)}
        />
      )}

      {isInCart(product.item_id) === true && (
        <IoTrash
          size={35}
          className="product-add-icon"
          onClick={() => removeFromCart(product.item_id)}
        />
      )}

      {(isInCart(product.id) === false || isInCart(product.id) === null) &&
        product.stock > 0 && (
          <IoIosAddCircle
            size={35}
            className="product-add-icon"
            onClick={() => addToCart(product.id)}
          />
        )}

      {product.item_id && (
        <>
          {(isInCart(product.item_id) === false ||
            isInCart(product.item_id) === null) &&
            product.product_label !== "Out of Stock" &&
            product.product_label !== "Coming Soon" &&
            product.product_label !== "Pre Order" &&
            product.product_label !== "Discontinue" && (
              <IoIosAddCircle
                size={35}
                className="product-add-icon"
                onClick={() => addToCart(product.item_id)}
              />
            )}
        </>
      )}
    </div>
  );
};

export default ProductCard;