import React, { useEffect, useContext } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { AppContext } from "./configs/AppContext";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import moment from "moment";

import Login from "./pages/Login";
import Home from "./pages/Home";
import Products from "./pages/Products";
import Customers from "./pages/Customers";
import CustomerSearch from "./pages/CustomerSearch";
import CustomerCreate from "./pages/CustomerCreate";
import CustomerUpdate from "./pages/CustomerUpdate";
import AccurateCallback from "./pages/AccurateCallback";
import PickupAtStore from "./pages/PickupAtStore";
import ModalLoading from "./components/ModalLoading";
import ToastMessage from "./components/ToastMessage";
import PrivateRoute from "./configs/PrivateRoute";
import Layout from "./components/Layout";
import Warehouse from "./pages/Warehouse";
import CustomerHistory from "./pages/CustomerHistory";
import CustomerDP from "./pages/CustomerDP";
import { APISV } from './configs/API';

import theme from "./configs/theme";
import Events from "./pages/Events";
import Scanner from "./pages/Scanner";

export default function App() {
  const [state, dispatch] = useContext(AppContext);

  useEffect(() => {
    const removeActiveEvent = async () => {
      var activeCartEvent = localStorage.getItem("active_cart_event");
      
      if (activeCartEvent) {
        activeCartEvent = JSON.parse(activeCartEvent)
        await APISV.delete(`events/cart/${activeCartEvent.id}`);
      }
    }
    if (localStorage.token) {
      if (
        localStorage.expired_at &&
        moment(new Date()).isAfter(localStorage.expired_at)
      ) {
        removeActiveEvent()
        dispatch({
          type: "LOGOUT",
        });
      } else {
        const activeCustomer = localStorage.getItem("active_customer");
        const activeCart = localStorage.getItem("active_cart");
        const activeCartEvent = localStorage.getItem("active_cart_event");
        const activeTax = localStorage.getItem("active_tax");

        if (activeCustomer) {
          dispatch({
            type: "SET_ACTIVE_CUSTOMER",
            payload: JSON.parse(activeCustomer),
          });
        }

        if (activeCart) {
          dispatch({
            type: "SET_ACTIVE_CART",
            payload: JSON.parse(activeCart),
          });
        }

        if (activeCartEvent) {
          dispatch({
            type: "SET_ACTIVE_CART_EVENT",
            payload: JSON.parse(activeCartEvent),
          });
        }

        if (activeTax) {
          dispatch({
            type: "SET_ACTIVE_TAX",
            payload: JSON.parse(activeTax),
          });
        }

        dispatch({
          type: "SET_LOGIN",
        });
        dispatch({
          type: "SET_LOADING",
          payload: false,
        });
      }
    } else {
      removeActiveEvent()
      dispatch({
        type: "LOGOUT",
      });
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ModalLoading />
        <ToastMessage />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/accurate_callback" element={<AccurateCallback />} />
            <Route path="/_aol/return/accurate_callback" element={<AccurateCallback />} />
            <Route element={<PrivateRoute />}>
              <Route path="/" element={<Home />} />
              <Route path="/products" element={<Products />} />
              <Route path="/events" element={<Events />} />
              <Route path="/scanner" element={<Scanner />} />
              <Route path="/customers" element={<Customers />} />
              <Route path="/customers/search" element={<CustomerSearch />} />
              <Route path="/customers/create" element={<CustomerCreate />} />
              <Route path="/customers/update" element={<CustomerUpdate />} />
              <Route path="/pickup-at-store" element={<PickupAtStore />} />
              <Route path="/warehouse" element={<Warehouse />} />
              <Route path="/customers/history" element={<CustomerHistory />} />
              <Route path="/customers/dp" element={<CustomerDP />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}