import React from "react";
import { Modal } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";

const ModalPickupTable = ({ show, handleClose }) => {
  return (
    <Modal centered show={show} onHide={handleClose} backdrop="static">
      <Modal.Body>
        <div className="d-flex justify-content-between mb-3">
          <div>
            <div className="modal-coupon-header">Pengambilan Order</div>
            <div className="color-grey">
              Pastikan semua item sudah diambil oleh customer
            </div>
          </div>

          <IoMdClose
            className="cursor-pointer"
            size={20}
            onClick={handleClose}
          />
        </div>

        <div className="fw-bold">List Barang:</div>
        <ul>
          <li>Lexar 32GB Professional 800x SDHC UHS-I Memory Card</li>
          <li>
            LINDY 36891 1M Kabel USB Type C to Micro USB DAC Cable 480Mbps
          </li>
        </ul>

        <small className="color-grey">
          *Proses ini akan menyelesaikan status pesanan pembeli
        </small>

        <div className="d-flex justify-content-between gap-2 mt-4">
          <button className="btn btn-secondary w-100" onClick={handleClose}>
            Cancel
          </button>
          <button className="btn btn-primary w-100" onClick={handleClose}>
            Submit
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalPickupTable;
