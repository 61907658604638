import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../configs/AppContext";

import { FaRegEdit, FaPlusCircle } from "react-icons/fa";
import {
  MdOutlineShoppingCart,
  MdPeopleOutline,
  MdHistory,
  MdOutlineRequestPage,
  MdEvent,
  MdOutlineDocumentScanner,
} from "react-icons/md";
import { IoStorefrontOutline } from "react-icons/io5";
import { IoIosCloseCircleOutline } from "react-icons/io";

import {
  ProSidebar,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

import Officer from "../assets/images/doss-officer-webp.webp";
import { APP_VERSION } from "../configs/App";

const Sidebar = () => {
  const [state, dispatch] = useContext(AppContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { activeCustomer } = state;

  const removeActiveCustomer = () => {
    dispatch({
      type: "REMOVE_ACTIVE_CUSTOMER",
    });
  };

  return (
    <ProSidebar className="sidebar" collapsed={state.collapse}>
      <SidebarHeader>
        {state.collapse ? (
          <img
            src="https://main.mobile.doss.co.id/storage/uploads/2022/03/doss-single.webp"
            alt="logo doss"
            className="sidebar-logo-small"
            onClick={() => navigate("/")}
          />
        ) : (
          <img
            src="https://main.mobile.doss.co.id/storage/uploads/2022/01/logo-doss-black.webp"
            alt="logo doss"
            className="sidebar-logo"
            onClick={() => navigate("/")}
          />
        )}
      </SidebarHeader>
      <SidebarContent>
        <div
          className={`sidebar-menu ${(pathname === "/customers" ||
            pathname === "/customers/search" ||
            pathname === "/customers/create" ||
            pathname === "/customers/update") &&
            "active"
            } mb-2`}
          onClick={() => navigate("/customers/search")}
        >
          <MdPeopleOutline size={25} />
          {!state.collapse && <div className="ms-2">Customer</div>}
        </div>

        {activeCustomer && (
          <div
            className={`sidebar-menu ${pathname === "/customers/history" && "active"
              } mb-2`}
            onClick={() => navigate("/customers/history")}
          >
            <MdHistory size={25} />
            {!state.collapse && <div className="ms-2">History</div>}
          </div>
        )}

        {activeCustomer && (
          <div
            className={`sidebar-menu ${pathname === "/customers/dp" && "active"
              } mb-2`}
            onClick={() => navigate("/customers/dp")}
          >
            <MdOutlineRequestPage size={25} />
            {!state.collapse && <div className="ms-2">Down Payment</div>}
          </div>
        )}

        <div
          className={`sidebar-menu ${pathname === "/products" && "active"
            } mb-2`}
          onClick={() => navigate("/products?")}
        >
          <MdOutlineShoppingCart size={25} />
          {!state.collapse && <div className="ms-2">Product</div>}
        </div>
        <div
          className={`sidebar-menu ${pathname === "/events" && "active"
            } mb-2`}
          onClick={() => navigate("/events?")}
        >
          <MdEvent size={25} />
          {!state.collapse && <div className="ms-2">Events</div>}
        </div>
        <div
          className={`sidebar-menu ${pathname === "/scanner" && "active"
            } mb-2`}
          onClick={() => navigate("/scanner?")}
        >
          <MdOutlineDocumentScanner size={25} />
          {!state.collapse && <div className="ms-2">Scanner</div>}
        </div>
        <div
          className={`sidebar-menu ${pathname === "/pickup-at-store" && "active"
            } mb-2`}
          onClick={() => navigate("/pickup-at-store")}
        >
          <IoStorefrontOutline size={25} />
          {!state.collapse && <div className="ms-2">Pickup At Store</div>}
        </div>
      </SidebarContent>
      <SidebarFooter>
        {!state.collapse && (
          <div className="mt-2">Transaksi untuk Customer</div>
        )}
        {!state.collapse && activeCustomer && (
          <div className="sidebar-customer-card">
            <IoIosCloseCircleOutline
              size={25}
              className="sidebar-close-customer"
              onClick={removeActiveCustomer}
            />
            <img
              src={activeCustomer.avatar || Officer}
              alt="logo account"
              className="sidebar-customer-img"
            />

            <div className="sidebar-customer-name mt-2">
              {activeCustomer.fullname}
            </div>
            <div className="sidebar-customer-info">{activeCustomer.email}</div>
            <div className="sidebar-customer-info">{activeCustomer.phone}</div>

            <button
              className="btn btn-secondary mt-2"
              onClick={() => navigate("/customers/update")}
            >
              Ubah Profile
            </button>
          </div>
        )}

        {!state.collapse && !activeCustomer && (
          <div className="sidebar-customer-card">
            <img
              src={Officer}
              alt="logo account"
              className="sidebar-officer-img"
            />

            <div className="sidebar-customer-name mt-2">
              Customer belum dipilih
            </div>
            <div className="sidebar-customer-info"></div>

            <button
              className="btn btn-secondary mt-2"
              onClick={() => navigate("/customers/search")}
            >
              Pilih Customer
            </button>
          </div>
        )}

        {state.collapse && (
          <>
            <img
              src={
                activeCustomer && activeCustomer.avatar
                  ? activeCustomer.avatar
                  : Officer
              }
              alt="logo account"
              className="sidebar-customer-img mt-4"
            />
            {activeCustomer ? (
              <FaRegEdit size={20} className="mt-3 cursor-pointer" />
            ) : (
              <FaPlusCircle
                size={20}
                className="mt-3 cursor-pointer"
                onClick={() => navigate("/customers/create")}
              />
            )}
          </>
        )}

        {!state.collapse && (
          <div className="sidebar-footer-txt mt-3">v{APP_VERSION} - &copy;DOSS - @{new Date().getFullYear()}  </div>
        )}

        {state.collapse && (
          <div className="sidebar-footer-txt mt-5">v{APP_VERSION} <br />  &copy;DOSS</div>
        )}
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Sidebar;