import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    // Name of the component
    MuiAutocomplete: {
      styleOverrides: {
        endAdornment: {
          right: "13px !important",
        },
        root: {
          width: "100% !important",
        },
        inputRoot: {
          fontSize: "14px",
          backgroundColor: "#FFFF",
          border: "1px solid #DCDCDC",
          borderTopLeftRadius: "10px",
          borderBottomLeftRadius: "10px",
          borderTopRightRadius: "0px",
          borderBottomRightRadius: "0px",

          "&.Mui-disabled": {
            backgroundColor: "#EDF0F2",
            border: "1px solid transparent !important",
            outline: "none !important",
          },
          "&.Mui-visited": {
            backgroundColor: "#FFFF",
          },
          "&.Mui-active": {
            backgroundColor: "#FFFF",
          },
          "&.Mui-focused": {
            backgroundColor: "#FFFF",
            borderColor: "rgba(238, 96, 38, 0.9)",
            boxShadow:
              "inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(238, 96, 38, 0.6)",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: "1px solid transparent !important",
        },
      },
    },
  },
});

export default theme;
