import React, { useContext, useState, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import { AppContext } from "../configs/AppContext";
import NumberFormat from "react-number-format";
import { BsCheckCircleFill } from "react-icons/bs";

import { APIO2O, APISV, APIDOSS } from "../configs/API";

import ModalCoupon from "./ModalCoupon";
import TotalSummary from "./TotalSummary";
import CartItem from "./CartItem";
import TitleSidebar from "./TitleSidebar";
import ModalFormCoupon from "./ModalFormCoupon";

const CartSidebar = () => {
  const [state, dispatch] = useContext(AppContext);
  const [coupon, setCoupon] = useState("");
  const [couponDisable, setCouponDisable] = useState(false);
  const [modalCoupon, setModalCoupon] = useState(false);
  const [modalForm, setModalForm] = useState(false);
  const [isCouponSet, setIsCouponSet] = useState(null);
  const branch = JSON.parse(localStorage.getItem("branch"));
  const warehouse = JSON.parse(localStorage.getItem("warehouse"));
  const dbList = JSON.parse(localStorage.getItem("db_list"));
  const { id: branch_id } = branch;
  const { id: warehouse_id } = warehouse;

  const { activeCart, activeCustomer, activeTax } = state;

  const handleClose = () => {
    dispatch({
      type: "SET_CART_SHOW",
      payload: false,
    });
  };

  const handleNext = () => {

    dispatch({
      type: "SET_CART_SHOW",
      payload: false,
    });
    dispatch({
      type: "SET_SN_SHOW",
      payload: true,
    });
  };

  const handleDecrease = async (item) => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const payload = {
        quantity: item.quantity - 1,
      };
      const res = await APISV.patch(
        `s-cart/${item.cart_id}/item/${item.id}`,
        payload
      );

      const resCart = await APISV.get(`cart/${activeCart.order_id}`);
      if (resCart.data.error == 0) {
        dispatch({
          type: "SET_ACTIVE_CART",
          payload: resCart.data.data,
        });
      } else {
        dispatch({
          type: "DELETE_ACTIVE_CART",
        });
        dispatch({
          type: "DELETE_ACTIVE_TAX",
        });
      }

      // handleTax();
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      if (error.response.data) {
        alert(error.response.data.message);
      } else {
        alert(error);
      }
    }
  };

  const handleIncrease = async (item) => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const payload = {
        quantity: item.quantity + 1,
      };
      const res = await APISV.patch(
        `s-cart/${item.cart_id}/item/${item.id}`,
        payload
      );

      const resCart = await APISV.get(`cart/${activeCart.order_id}`);
      if (resCart.data.error == 0) {
        dispatch({
          type: "SET_ACTIVE_CART",
          payload: resCart.data.data,
        });
      } else {
        dispatch({
          type: "DELETE_ACTIVE_CART",
        });
        dispatch({
          type: "DELETE_ACTIVE_TAX",
        });
      }

      // handleTax();
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      if (error.response.data) {
        alert(error.response.data.message);
      } else {
        alert(error);
      }
    }
  };

  const handleDelete = async (item) => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const res = await APISV.delete(`s-cart/${item.cart_id}/item/${item.id}`);

      const resCart = await APISV.get(`cart/${activeCart.order_id}`);
      if (resCart.data.error == 0) {
        dispatch({
          type: "SET_ACTIVE_CART",
          payload: resCart.data.data,
        });
      } else {
        dispatch({
          type: "DELETE_ACTIVE_CART",
        });
        dispatch({
          type: "DELETE_ACTIVE_TAX",
        });
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      if (error.response.data) {
        alert(error.response.data.message);
      } else {
        alert(error);
      }
    }
  };

  const handleAddCart = async (e) => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const payload = {
        product_id: e.discounted_product_id,
        quantity: 1,
        branch_id,
        warehouse_id,
      };
      const res = await APISV.post(`s-cart/${activeCart.id}/item`, payload);

      if (res.data.error == 0) {
        const resCart = await APISV.get(`cart/${activeCart.order_id}`);
        if (resCart.data.error == 0) {
          dispatch({
            type: "SET_ACTIVE_CART",
            payload: resCart.data.data,
          });
        } else {
          dispatch({
            type: "DELETE_ACTIVE_CART",
          });
          dispatch({
            type: "DELETE_ACTIVE_TAX",
          });
        }

        dispatch({
          type: "SET_TOAST",
          payload: {
            isOpen: true,
            message: "Berhasil ditambahkan ke Keranjang",
          },
        });
      } else {
        alert(
          `Error ${res.data.error} | ${res.data.message} \nDB: ${dbList.alias} [${dbList.id}] \nBranch: ${branch.name} [${branch.id}] \nWarehouse: ${warehouse.name} [${warehouse.id}]`
        );
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      if (error.response.data) {
        alert(error.response.data.message);
      } else {
        alert(error);
      }
    }
  };

  const submitCoupon = async (e, couponCode) => {
    e && e.preventDefault();
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });
      const body = { code: couponCode ? couponCode : coupon };
      const res = await APISV.post(`s-cart/${activeCart.id}/coupon`, body);

      if (res.data.error == 0) {
        dispatch({
          type: "SET_TOAST",
          payload: {
            isOpen: true,
            message: "Berhasil apply coupon",
          },
        });

        setCouponDisable(true);

        const resCart = await APISV.get(`cart/${activeCart.order_id}`);
        if (resCart.data.error == 0) {
          dispatch({
            type: "SET_ACTIVE_CART",
            payload: resCart.data.data,
          });
        } else {
          dispatch({
            type: "DELETE_ACTIVE_CART",
          });
          dispatch({
            type: "DELETE_ACTIVE_TAX",
          });
        }
      } else {
        alert(res.data.message);
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      alert(error.response.data.message);
    }
  };

  const addCoupon = async () => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const res = await APIO2O.get("platform/account/glue");

      if (res.data.error == 0) {
        window.open(res.data.data.redirect);
        setModalCoupon(true);
      } else {
        alert(res.data.message);
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      alert(error.response.data.message);
    }
  };

  const getActiveCart = async () => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });
      const resCart = await APISV.get(`cart/${activeCart.order_id}`);

      if (resCart.data.error == 0) {
        dispatch({
          type: "SET_ACTIVE_CART",
          payload: resCart.data.data,
        });
      } else {
        dispatch({
          type: "DELETE_ACTIVE_CART",
        });
        dispatch({
          type: "DELETE_ACTIVE_TAX",
        });
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      alert(error);
    }
  };

  useEffect(() => {
    state.cartShow && getActiveCart();
  }, [state.cartShow]);

  return (
    <>
      {activeCustomer ? (
        <ModalCoupon
          show={modalCoupon}
          handleClose={() => setModalCoupon(false)}
          customerId={activeCustomer.id}
          applyCoupon={(e, couponCode) => submitCoupon(e, couponCode)}
        />
      ) : null}
      {activeCustomer && activeCart ? (
        <ModalFormCoupon
          show={modalForm}
          handleClose={() => setModalForm(false)}
          activeCart={activeCart}
          setIsCouponSet={() => setIsCouponSet(true)}
        />
      ) : null}
      {activeCart ? (
        <Offcanvas show={state.cartShow} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <TitleSidebar activeCart={activeCart} />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="cart-sidebar-item">
              {activeCart &&
                activeCart.items.map((item) => (
                  <CartItem
                    key={item.id}
                    item={item}
                    handleDecrease={(e) => handleDecrease(e)}
                    handleIncrease={(e) => handleIncrease(e)}
                    handleDelete={(e) => handleDelete(e)}
                    handleAddCart={(e) => handleAddCart(e)}
                    getLatestCartInfo={getActiveCart}
                  />
                ))}
            </div>

            <div className="row mt-2">
              <div className="col-md-6">
                <div
                  className="cart-sidebar-new-coupon mb-2"
                  onClick={addCoupon}
                >
                  Tambahkan Kupon Diskon
                </div>
                <form onSubmit={(e) => submitCoupon(e)}>
                  <label>Coupon</label>
                  <div className="cart-sidebar-coupon">
                    <input
                      className="form-control"
                      type="text"
                      placeholder={activeCart.coupon || "Masukan coupon.."}
                      value={coupon}
                      onChange={(e) => setCoupon(e.target.value)}
                      disabled={couponDisable}
                    />
                    {couponDisable && (
                      <div
                        className="btn-edit-coupon"
                        onClick={() => {
                          setCouponDisable(false);
                          setCoupon("");
                        }}
                      >
                        Ubah
                      </div>
                    )}
                    <button
                      type="submit"
                      className={`btn ${couponDisable ? "btn-success" : "btn-primary"
                        } btn-apply-coupon ms-2`}
                      disabled={couponDisable || coupon === ""}
                    >
                      {couponDisable ? (
                        <BsCheckCircleFill size={25} color="#FFFF" />
                      ) : (
                        "Apply"
                      )}
                    </button>
                  </div>
                </form>
              </div>

              {activeCart.coupon && !isCouponSet && (
                <div className="col-md-6 d-flex flex-column justify-content-end align-items-end">
                  <div className="fw-bold d-flex justify-content-end mb-2">
                    Atur diskon per item
                  </div>
                  <button
                    className="btn btn-secondary"
                    style={{ width: "150px" }}
                    onClick={() => setModalForm(true)}
                  >
                    Setting Diskon
                  </button>
                </div>
              )}
            </div>

            <TotalSummary activeCart={activeCart} tax={activeTax} />

            <hr />

            <div className="d-flex justify-content-between align-items-center">
              <div className="fw-bold">Total Belanja</div>

              <div className="fw-bold" style={{ color: "red" }}>
                <NumberFormat
                  value={activeCart.total}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix={"IDR "}
                />
              </div>
            </div>

            <button
              className="btn btn-primary btn-checkout fw-bold mt-2"
              onClick={handleNext}
              disabled={activeCart.coupon && !isCouponSet}
            >
              Lanjutkan
            </button>
          </Offcanvas.Body>
        </Offcanvas>
      ) : null}
    </>
  );
};

export default CartSidebar;