import React, { useState, useContext, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { IoMdClose, IoMdArrowDropdown } from "react-icons/io";
import NumberFormat from "react-number-format";

import { AppContext } from "../configs/AppContext";
import { APIUTILS, APIO2O, APIDOSS, APISV } from "../configs/API";
import { idrToNumber } from "../configs/helper";

const ModalFormCoupon = ({ show, handleClose, activeCart, setIsCouponSet }) => {
  const { items, coupon_discount, id: cart_id } = activeCart;
  const [formData, setFormData] = useState(null);
  const [totalCoupon, setTotalCoupon] = useState(0);
  const [loading, setLoading] = useState(false);

  const postItem = async (url, payload) => {
    try {
      const res = await APISV.patch(url, payload);
      console.log("res", res.data);
    } catch (error) {
      alert(error);
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      Object.entries(formData).forEach(([key, value]) => {
        const itemID = key.substring(5);

        const payload = {
          coupon_applied_amount: idrToNumber(value),
          quantity: getQuantity(itemID),
        };

        const url = `s-cart/${cart_id}/item/${itemID}`;

        postItem(url, payload);
      });

      setIsCouponSet();
      handleClose();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error);
    }
  };

  const sumTotal = () => {
    let total = 0;
    Object.entries(formData).forEach(
      ([key, value]) => (total = total + idrToNumber(value))
    );

    setTotalCoupon(total);
  };

  const getQuantity = (item_id) => {
    return items.find((item) => item.id === parseInt(item_id)).quantity;
  };

  useEffect(() => {
    let obj = {};
    items && items.map((item) => (obj[`item-${item.id}`] = ""));
    setFormData(obj);
  }, [items]);

  useEffect(() => {
    formData && sumTotal();
  }, [formData]);

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Body>
        <div className="d-flex justify-content-between mb-3">
          <div className="fw-bold">Form Diskon Coupon</div>
          <IoMdClose size={20} className="cursor-pointer" />
        </div>

        <form onSubmit={handleSubmit}>
          {items &&
            formData &&
            items.map((item) => (
              <div className="mb-3" key={item.id}>
                <div className="mb-2">
                  <strong>#{item.product_id}</strong> - {item.product.name}
                </div>
                <NumberFormat
                  value={formData[`item-${item.id}`]}
                  name={`item-${item.id}`}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  prefix={"IDR "}
                  thousandSeparator="."
                  decimalSeparator=","
                  className="form-control"
                  placeholder="Jumlah potongan dari kupon"
                />
              </div>
            ))}

          <hr />

          <div className="d-flex justify-content-between mt-3">
            <div>Total Diskon Coupon All Item</div>
            <NumberFormat
              value={totalCoupon}
              displayType="text"
              prefix={"IDR "}
              thousandSeparator="."
              decimalSeparator=","
              className="fw-bold"
            />
          </div>

          <hr />

          <div className="d-flex justify-content-between mt-3">
            <div>Diskon Kupon</div>

            <NumberFormat
              value={coupon_discount}
              displayType="text"
              prefix={"IDR "}
              thousandSeparator="."
              decimalSeparator=","
              className="fw-bold"
            />
          </div>

          <small className="color-gray">
            *Total discount coupon all item harus sama dengan diskon kupon
          </small>

          <div className="w-100">
            <button
              type="submit"
              className="btn btn-primary w-100 mt-3"
              disabled={totalCoupon !== coupon_discount || loading}
            >
              {loading ? (
                <Spinner
                  animation="border"
                  style={{ color: "#ffff", width: "15px", height: "15px" }}
                />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalFormCoupon;
