import React, { useState, useContext, useEffect } from "react";
import { Spinner } from "react-bootstrap";

import { BsCheckCircleFill } from "react-icons/bs";

import { APISV } from "../configs/API";
import { AppContext } from "../configs/AppContext";

import ComboBox from "./ComboBox";

const SNInputChild = ({ item, filterSN, setFilterSN, cartId, itemId }) => {
  const [state, dispatch] = useContext(AppContext);
  const [serialNumber, setSerialNumber] = useState("");
  const [snList, setSnList] = useState([]);
  const [loading, setLoading] = useState(false);

  const { activeCart } = state;

  const [verified, setVerified] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const payload = {
        serial_number: serialNumber,
      };
      const res = await APISV.post(
        `s-cart/${cartId}/item/${item.id}/sn`,
        payload
      );

      if (res.data.error == 0) {
        setVerified(true);

        setFilterSN((oldArray) => [...oldArray, serialNumber]);

        const resCart = await APISV.get(`cart/${activeCart.order_id}`);
        if (resCart.data.error == 0) {
          dispatch({
            type: "SET_ACTIVE_CART",
            payload: resCart.data.data,
          });
        } else {
          dispatch({
            type: "DELETE_ACTIVE_CART",
          });
          dispatch({
            type: "DELETE_ACTIVE_TAX",
          });
        }
      } else {
        alert(res.data.description);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert(error);
    }
  };

  const getSerialNumber = async () => {
    try {
      const res = await APISV.get(`s-cart/${cartId}/item/${item.id}/sn`);

      if (res.data.error == 0) {
        let filtered = res.data.data.serial_numbers.filter(
          (item) => !filterSN.includes(item)
        );
        setSnList(filtered);
      } else {
        alert(res.data.description);
      }
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  useEffect(() => {
    if (item.serial_numbers.length !== 0 && item.serial_numbers[0] !== "") {
      setSerialNumber(item.serial_numbers[0]);
      setVerified(true);
    }
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row d-flex  mb-3">
          <div className="col-md-2 ">
            <div className="sn-input-protection">Product ID</div>
            <div className="fw-bold">#{item.product_id}</div>
          </div>
          <div className="col-md-10 d-flex flex-column">
            <div className="row mb-3">
              <div className="col fw-bold">{item.product.name}</div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="d-flex">
                  <ComboBox
                    getOptions={getSerialNumber}
                    snList={snList}
                    onChange={(e) => setSerialNumber(e)}
                    disabled={verified}
                    defaultValue={item.serial_numbers[0] || ""}
                  />
                  <button
                    type="submit"
                    className={`btn ${
                      verified ? "btn-success" : "btn-primary"
                    } sn-button`}
                    disabled={verified}
                  >
                    {loading && (
                      <Spinner
                        animation="border"
                        style={{
                          color: "#ffff",
                          width: "25px",
                          height: "25px",
                        }}
                      />
                    )}
                    {!loading && !verified && "Submit"}
                    {!loading && verified && (
                      <BsCheckCircleFill color="#FFFF" size={25} />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default SNInputChild;
