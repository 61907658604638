import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../configs/AppContext";
import Skeleton from "react-loading-skeleton";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { IoIosCheckmarkCircle, IoMdArrowDropdown } from "react-icons/io";

import Officer from "../assets/images/doss-officer-webp.webp";

import Sidebar from "../components/Sidebar";
import TopNavbar from "../components/TopNavbar";
import { minTwoDigits } from "../helpers/minTwoDigit";

import { APISV } from "../configs/API";
import { capitalizeFirstLetter } from "../helpers/capitalizeFirstLetter";

const CustomerCreate = () => {
  const [state, dispatch] = useContext(AppContext);
  const navigate = useNavigate();
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    fullname: "",
    title: "1",
    marketplace: "",
  });
  const [dialCode, setDialCode] = useState("62");
  const [phone, setPhone] = useState("");
  const [userType, setUserType] = useState(null);
  const [currentTypeId, setCurrentTypeId] = useState(null);
  const [currentType, setCurrentType] = useState("reguler");
  const [mpList, setMpList] = useState(null);

  const { email, fullname, title, marketplace } = formData;

  const filterPhone = (phone_number) => {
    const filter = phone_number.replace(/[+-\s\(\)]/g, "").replace(/^62/, "0");

    if (filter[0] === "0") {
      return filter.substring(1);
    } else {
      return filter;
    }
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const payload = formData;
      payload.phone = filterPhone(phone);
      payload.dial_code = dialCode;
      payload.title = parseInt(payload.title);

      payload.user_type_id = currentTypeId;

      if (currentType === "reguler" || currentType === "influencer") delete payload.marketplace;

      const res = await APISV.post("account/create", payload);

      if (res.status == 200) {
        if (res.data.error == 422) {
          alert(JSON.stringify(res.data.errors));
        }
        if (res.data.error === 404) {
          alert(res.data.description);
        }
        if (res.data.error === 0) {
          setCustomer(res.data.data);
        }
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert(error);
    }
  };

  const handleChoose = async () => {
    try {
      setLoading(true);

      if (state.activeCart) {
        const res = await APISV.delete(`s-cart/${state.activeCart.id}`);

        if (res.data.error == 0) {
          dispatch({
            type: "DELETE_ACTIVE_CART",
          });
          dispatch({
            type: "DELETE_ACTIVE_TAX",
          });

          dispatch({
            type: "SET_CART_SHOW",
            payload: false,
          });
          dispatch({
            type: "SET_SN_SHOW",
            payload: false,
          });
          dispatch({
            type: "SET_TAX_SHOW",
            payload: false,
          });
        } else {
          alert(res.data.description);
        }
      }

      dispatch({
        type: "SET_ACTIVE_CUSTOMER",
        payload: customer,
      });

      navigate("/products");

      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error.response.data.message);
    }
  };

  const getUserType = async () => {
    try {
      setLoading(true);
      const res = await APISV.get("account/usertype");
      const newTypes = res?.data?.data?.map((val) => ({...val, name: val.name.toLowerCase()}))
      setUserType(newTypes);

      for (const typ of newTypes) {
        if (typ.name === "reguler") {
          setCurrentType(typ.name);
          setCurrentTypeId(typ.id)
        }
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error.response.data.message);
    }
  };

  const getMarketPlace = async () => {
    try {
      setLoading(true);
      const res = await APISV.get("account/marketplace");
      setMpList(res.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error.response.data.message);
    }
  };

  const getMPUser = async () => {
    try {
      setLoading(true);
      const res = await APISV.get("account/marketplace/user");
      console.log("res", res.data);
      // setMpList(res.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error.response.data.message);
    }
  };

  const handleChangeType = (value) => {
    // setCurrentType(value);
    changeType(value)
    setFormData({ ...formData, email: "", fullname: "" });

    if (value === "marketplace") {
      // const phone = `${Date.now()}`;
      const date = new Date();
      let year = minTwoDigits(date.getFullYear());
      let month = minTwoDigits(date.getMonth());
      let day = minTwoDigits(date.getDate());
      let hours = minTwoDigits(date.getHours());
      let minutes = minTwoDigits(date.getMinutes());
      let seconds = minTwoDigits(date.getSeconds());
      const phone = `999${year}${month}${day}${hours}${minutes}${seconds}`;
      setPhone(phone);
    } else {
      setPhone("");
    }

    // if (value === "marketplace") {
    //   const email = `${Date.now()}@doss.xyz`;
    //   const name = `user-${Date.now()}`;
    //   const phone = `${Date.now()}`;
    //   setFormData({ ...formData, email, fullname: name });
    //   setPhone(phone);
    // } else {
    //   setFormData({ ...formData, email: "", fullname: "" });
    //   setPhone("");
    // }
  };

  const handleChangeName = (value) => {
    if (currentType === "marketplace") {
      const email = `${value}@doss.xyz`;
      setFormData({ ...formData, fullname: value, email });
    } else {
      setFormData({ ...formData, fullname: value });
    }
  };

  const handleEdit = () => {
    dispatch({
      type: "SET_ACTIVE_CUSTOMER",
      payload: customer,
    });

    navigate("/customers/update");
  };

  useEffect(() => {
    // getMPUser();
    getUserType();
  }, []);

  useEffect(() => {
    currentType === "marketplace" && !mpList && getMarketPlace();
  }, [currentType]);

  function changeType(value) {
    for (const typ of userType) {
      if (typ.name == value) {
        setCurrentType(typ.name);
        setCurrentTypeId(typ.id)
      }
    }
  }

  return (
    <div className="main-container">
      <Sidebar />

      <div
        className="w-100"
        style={{
          paddingLeft: state.collapse ? "80px" : "270px",
          transition: "width, left, right, 0.3s",
        }}
      >
        <TopNavbar title="Customer" disableSearch={true} />

        <div className="d-flex justify-content-between px-4">
          <h1 className="header-h1">Tambah Customer Baru</h1>

          <button
            className="btn btn-primary customer-add-btn"
            onClick={() => navigate("/customers/search")}
          >
            Cari Customer
          </button>
        </div>

        <div className="row">
          <div className="col-md-6">
            <form onSubmit={handleCreate}>
              <div className="form-group px-4">
                <label>Tipe User</label>

                <div className="position-relative">
                  <select
                    value={currentType}
                    onChange={(e) => handleChangeType(e.target.value)}
                    className="form-control"
                  >
                    <option value="">
                      - Pilih Type -
                    </option>
                    {userType &&
                      userType.map((user) => (
                        <option key={user.id} value={user.name}>
                          {capitalizeFirstLetter(user.name)}
                        </option>
                      ))}
                  </select>

                  <IoMdArrowDropdown
                    className="arrow-select"
                    size={25}
                    color="gray"
                  />
                </div>
              </div>

              {currentType === "marketplace" && (
                <div className="form-group px-4 mt-2">
                  <label>Sumber Marketplace</label>

                  <div className="position-relative">
                    <select
                      value={marketplace}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          marketplace: e.target.value,
                        })
                      }
                      className="form-control"
                    >
                      <option value="">
                        - Pilih Marketplace -
                      </option>
                      {mpList &&
                        mpList.map((mp) => (
                          <option key={mp} value={mp}>
                            {capitalizeFirstLetter(mp)}
                          </option>
                        ))}
                    </select>

                    <IoMdArrowDropdown
                      className="arrow-select"
                      size={25}
                      color="gray"
                    />
                  </div>
                </div>
              )}

              <div className="form-group px-4 mt-2">
                <label className="mt-3">Nama Lengkap</label>
                <div className="position-relative">
                  <select
                    className="input-select"
                    value={title}
                    onChange={(e) =>
                      setFormData({ ...formData, title: e.target.value })
                    }
                  >
                    <option value="1">Mr</option>
                    <option value="2">Ms.</option>
                    <option value="3">Mrs.</option>
                  </select>
                  <input
                    type="text"
                    className="form-control input-with-select"
                    placeholder="Masukan nama lengkap customer"
                    name="fullname"
                    value={fullname || ""}
                    onChange={(e) => handleChangeName(e.target.value)}
                  />
                </div>

                <label className="mt-3">Alamat Email</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Masukan alamat email customer"
                  name="email"
                  value={email || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />

                <label className="mt-3">Nomor Handphone (Whatsapp)</label>
                <PhoneInput
                  country={"id"}
                  placeholder=""
                  value={phone}
                  onChange={(e, data) => {
                    setPhone(e);
                    setDialCode(data.dialCode);
                  }}
                  countryCodeEditable={false}
                  inputClass="form-control w-100 h-48"
                />
                {/* <input
                  type="tel"
                  className="form-control"
                  placeholder="Masukan nomor handphone customer"
                  name="phone"
                  value={phone || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                  }
                /> */}

                <button
                  type="submit"
                  className="btn btn-primary w-100 mt-5"
                  disabled={
                    formData.email === "" ||
                    formData.phone === "" ||
                    formData.fullname === ""
                  }
                >
                  Tambah Customer
                </button>
              </div>
            </form>
          </div>

          <div className="col-md-6">
            {!customer && !loading && (
              <div className="customer-search-card">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={Officer}
                    alt="logo account"
                    className="officer-search-img"
                  />

                  <div className="officer-search-name mt-4">
                    Tambahkan Data Customer Baru
                  </div>
                  <div className="customer-search-info mt-2">
                    Hasil Customer Baru Akan Muncul Disini
                  </div>
                </div>
              </div>
            )}

            {loading && (
              <div className="customer-search-card">
                <div className="d-flex flex-column align-items-center">
                  <div className="customer-search-img">
                    <Skeleton circle style={{ height: "100%" }} />
                  </div>

                  <div className="customer-search-name w-100 mt-2">
                    <Skeleton />
                  </div>
                  <div className="customer-search-info w-100">
                    <Skeleton />
                  </div>
                  <div className="w-100">
                    <Skeleton />
                  </div>
                </div>

                <div className="w-100">
                  <Skeleton style={{ height: "30px" }} />
                  <Skeleton style={{ height: "30px" }} />
                </div>
              </div>
            )}

            {customer && !loading && (
              <div className="customer-search-card">
                <div className="d-flex flex-column align-items-center">
                  <div className="customer-search-img">
                    <img src={customer.avatar || Officer} alt="logo account" />
                    {/* <Skeleton circle style={{ height: "100%" }} /> */}
                  </div>

                  <div className="customer-search-name w-100 mt-2">
                    {customer.fullname}
                  </div>
                  <div className="customer-search-info">{customer.email}</div>
                  <div className="customer-search-info d-flex align-items-center">
                    {customer.phone}{" "}
                    {customer.unverified_phone == null && (
                      <span className="ms-1">
                        <IoIosCheckmarkCircle color="#3ec690" size={20} />
                      </span>
                    )}
                  </div>
                </div>

                <div>
                  <button
                    className="btn btn-primary w-100"
                    onClick={handleChoose}
                  >
                    Pilih Customer
                  </button>
                  <button
                    className="btn btn-secondary w-100 mt-2"
                    onClick={handleEdit}
                  >
                    Ubah Profile
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerCreate;
