import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../configs/AppContext";

import { IoIosSearch, IoIosAddCircle } from "react-icons/io";

import Sidebar from "../components/Sidebar";
import TopNavbar from "../components/TopNavbar";

const Customers = () => {
  const [state, dispatch] = useContext(AppContext);
  const navigate = useNavigate();

  return (
    <div className="main-container">
      <Sidebar />

      <div
        className="w-100"
        style={{
          paddingLeft: state.collapse ? "80px" : "270px",
          transition: "width, left, right, 0.3s",
        }}
      >
        <TopNavbar
          title="Customer"
          placeholder="Search customer.."
          disableSearch={true}
        />

        <div className="d-flex justify-content-between px-4">
          <h1 className="header-h1">Search Result for "Theressa Web"</h1>

          <div className="d-flex">
            <button
              className="btn btn-primary customer-add-btn d-flex align-items-center me-2"
              onClick={() => navigate("/customers/create")}
            >
              <IoIosAddCircle size={20} className="me-2" />
              Add Customer
            </button>

            <button
              className="btn btn-primary customer-add-btn"
              onClick={() => navigate("/customers/search")}
            >
              <IoIosSearch size={20} className="me-2" />
              Search Customer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;
