import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";

const TotalSummary = ({ activeCart, tax, isProduct = true }) => {
  const [childDiscount, setChildDiscount] = useState(null);

  const getTotalDiscount = () => {
    let total = 0;

    for (let i = 0; i < (activeCart?.items || []).length; i++) {
      const item = (activeCart?.items || [])[i];
      item.child_items &&
        item.child_items.map((child) => {
          total = total + child.discount;
        });
      
    }
    // activeCart.items.map((item) => {
    //   item.child_items &&
    //     item.child_items.map((child) => {
    //       total = total + child.discount;
    //     });
    // });

    setChildDiscount(total);
  };

  useEffect(() => {
    getTotalDiscount();
  }, [activeCart]);

  return (
    <div className="row mt-3">
      {isProduct ? <div className="col-md-4 mb-2">
        <div className="cart-sidebar-total-card-4">
          <div className="">DOSS Protection</div>
          <NumberFormat
            value={activeCart?.protection_price || "0"}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            prefix={"IDR "}
            className="fw-bold"
          />
        </div>
      </div>: null}
      
      <div className={`${isProduct ? 'col-md-4' : 'col-md-6'} mb-2`}>
        <div className="cart-sidebar-total-card-2">
          <div className="">Discount (Item)</div>
          <NumberFormat
            value={(activeCart?.discount || 0) + childDiscount}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            prefix={"- IDR "}
            className="fw-bold"
          />

          {(activeCart?.coupon_discount || 0) !== 0 && (
            <>
              <div className="">Discount (Coupon)</div>
              <NumberFormat
                value={activeCart?.coupon_discount || 0}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                prefix={"- IDR "}
                className="fw-bold"
              />
            </>
          )}
        </div>
      </div>
      <div className={`${isProduct ? 'col-md-4' : 'col-md-6'} mb-2`}>
        <div className="cart-sidebar-total-card">
          <div className="">{`Total ${activeCart?.event_id ? "Event" : "Barang"}`}</div>
          <NumberFormat
            value={(activeCart?.subtotal || 0) + childDiscount}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            prefix={"IDR "}
            className="fw-bold"
          />
        </div>
      </div>
    </div>
  );
};

export default TotalSummary;
