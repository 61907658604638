import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { AppContext } from "../configs/AppContext";
import { IoMenu } from "react-icons/io5";
import { BsArrowBarLeft, BsArrowBarRight, BsSearch } from "react-icons/bs";
import { APISV } from '../configs/API';

const storeNameList = (storeID) => {
  let store;
  switch (storeID) {
    case 50:
      return (store = "Head Office");
    case 54350:
      return (store = "DOSS Cideng");
    case 54351:
      return (store = "DOSS Kyai Maja");
    case 54400:
      return (store = "DOSS Ratu Plaza");
    case 54450:
      return (store = "DOSS Ambassador");
    case 54550:
      return (store = "DOSS STC");
    case 54600:
      return (store = "DOSS Tebah");
    case 54700:
      return (store = "DOSS Kemang");
    case 54500:
      return (store = "DOSS Event");
    default:
      return (store = "DOSS O2O");
  }
};

const TopNavbar = ({ title, placeholder, disableSearch }) => {
  const [state, dispatch] = useContext(AppContext);
  const [storeName, setStoreName] = useState("");

  const user = JSON.parse(localStorage.getItem("userInfo"));
  const branch = JSON.parse(localStorage.getItem("branch"));

  const navigate = useNavigate();

  const handleLogout = async () => {
    dispatch({
      type: "LOGOUT",
    });
    // sebelumnya untuk handle active chart yang nyangkut
    // var activeCartEvent = localStorage.getItem("active_cart_event");
    // if (activeCartEvent) {
    //   activeCartEvent = JSON.parse(activeCartEvent)
    //   const res = await APISV.delete(`events/cart/${activeCartEvent.id}`);

    //   if (res.data.error == 0) {
    //     dispatch({
    //       type: "LOGOUT",
    //     });
    //   } else {
    //     alert(res.data.message);
    //   }
    // } else {
    //   dispatch({
    //     type: "LOGOUT",
    //   });
    // }
  };

  useEffect(() => {
    if (branch) {
      setStoreName(branch.name);
    } else {
      navigate("/warehouse");
    }
  }, [branch]);

  return (
    <div className="top-navbar-container">
      <div className="d-flex align-items-center">
        {!state.collapse && (
          <BsArrowBarLeft
            size={30}
            className="top-navbar-collapse"
            onClick={() =>
              dispatch({
                type: "SET_COLLAPSE",
                payload: true,
              })
            }
          />
        )}

        {state.collapse && (
          <BsArrowBarRight
            size={30}
            className="top-navbar-collapse"
            onClick={() =>
              dispatch({
                type: "SET_COLLAPSE",
                payload: false,
              })
            }
          />
        )}
        <div className="header-h1 ms-2">{title}</div>
      </div>

      <div className="d-flex align-items-center">
        <div className="me-3">
          <div className="top-navbar-name">{user && user.name}</div>
          <div className="top-navbar-email">{user && user.email}</div>
        </div>

        <Dropdown>
          <Dropdown.Toggle
            id="dropdown-basic"
            className="btn btn-secondary d-flex align-items-center"
          >
            <img
              src="https://main.mobile.doss.co.id/storage/uploads/2021/11/account-compressed.webp"
              alt="logo user"
              className="top-navbar-user me-2"
            />

            <div className="top-navbar-name me-2">{storeName && storeName}</div>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleLogout()}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default TopNavbar;
