import React, { useState, useContext, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { IoMdClose, IoIosCheckmarkCircle } from "react-icons/io";

import { AppContext } from "../configs/AppContext";
import { APIO2O, APIUTILS } from "../configs/API";

const ModalOrderReady = ({ show, handleClose }) => {
  const [state, dispatch] = useContext(AppContext);
  const [isExist, setIsExist] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    trans_id: "",
  });

  const { trans_id } = formData;

  const checkTransaction = async (e) => {
    e.preventDefault();
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      if (!isExist) {
        const res = await APIO2O.get(`platform/pick/${trans_id}`);

        if (res.data.error == 0) {
          dispatch({
            type: "SET_LOADING",
            payload: false,
          });

          setIsExist(true);
        } else {
          dispatch({
            type: "SET_LOADING",
            payload: false,
          });
          alert(res.data.message);
        }
      } else {
        handlePickup();
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      alert(error.response.data.message);
    }
  };

  const handlePickup = async () => {
    try {
      const payload = { action: "ready" };
      const res = await APIO2O.patch(`/platform/pick/${trans_id}`, payload);

      if (res.data.error == 0) {
        dispatch({
          type: "SET_LOADING",
          payload: false,
        });

        dispatch({
          type: "SET_TOAST",
          payload: {
            isOpen: true,
            message: "Berhasil simpan status",
          },
        });

        setFormData({ trans_id: "" });
        setIsExist(false);

        handleClose();
      } else {
        dispatch({
          type: "SET_LOADING",
          payload: false,
        });
        alert(res.data.message);
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      alert(error.response.data.message);
    }
  };

  return (
    <Modal centered show={show} onHide={handleClose} backdrop="static">
      <Modal.Body>
        <div className="d-flex justify-content-between mb-4">
          <div>
            <div className="modal-coupon-header">Siapkan Pesanan</div>
            <div className="color-grey">
              Pastikan semua item sudah siap sesuai keterangan order
            </div>
          </div>

          <IoMdClose
            className="cursor-pointer"
            size={20}
            onClick={handleClose}
          />
        </div>

        <form onSubmit={checkTransaction}>
          <label>Trans ID</label>
          <div className="position-relative w-100">
            <input
              className="form-control"
              type="text"
              placeholder="Contoh: 21984"
              name="trans_id"
              value={trans_id}
              onChange={(e) =>
                setFormData({ ...formData, [e.target.name]: e.target.value })
              }
              disabled={isExist}
            />

            {isExist && (
              <IoIosCheckmarkCircle
                size={30}
                color="green"
                className="check-mark"
              />
            )}
          </div>

          {/* <label className="mt-3">Cabang</label>
          <div className="position-relative">
            <select
              className="form-control"
              name="location"
              value={location}
              onChange={(e) =>
                setFormData({ ...formData, [e.target.name]: e.target.value })
              }
            >
              {stores &&
                stores.map((store) => (
                  <option key={store.id} value={store.id}>
                    {store.name}
                  </option>
                ))}
            </select>

            <IoMdArrowDropdown
              className="arrow-select"
              size={25}
              color="gray"
            />
          </div> */}

          <small className="color-grey">
            *Customer akan mendapatkan notifikasi bahwa barang siap di ambil di
            store pilihan nya
          </small>

          {!isExist && (
            <button
              type="submit"
              className="btn btn-secondary btn-checkout fw-bold mt-4"
              style={{ height: "48px" }}
              disabled={loading || trans_id == ""}
            >
              {loading && (
                <Spinner
                  animation="border"
                  style={{ color: "#ffff", width: "25px", height: "25px" }}
                />
              )}
              {!loading && "Cek Transaksi"}
            </button>
          )}

          {isExist && (
            <button
              type="submit"
              className="btn btn-primary btn-checkout fw-bold mt-4"
              disabled={loading || trans_id == ""}
            >
              {loading && (
                <Spinner
                  animation="border"
                  style={{ color: "#ffff", width: "25px", height: "25px" }}
                />
              )}
              {!loading && "Siap Diambil"}
            </button>
          )}
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalOrderReady;
